import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../composants/image/Image"
import LayoutTutorielFR from "../../composants/layout/fr/LayoutTutorielFR"
import Lien from "../../composants/navigation/Lien"
import Section from "../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../js/client-es/utils"

import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageTestEOSRa: allPagesCompilationYaml(
      filter: { code: { eq: "testEOSRa" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "materielAstrophotographie"
            "logicielsAstrophotographie"
            "galerieVoieLactee"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: {
          in: ["materielPharenocturne", "materielUnchaletSousLesEtoiles"]
        }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["materielEcranarriereEOSRa"] } }
    ) {
      ...FragmentImagePaysageMoyen
    }
    imagesPaysagePetit: allImagesCompilationYaml(
      filter: { code: { in: ["materielBoiteEosRa", "materielCanonEOSRa"] } }
    ) {
      ...FragmentImagePaysagePetit
    }
    imagesPaysageTresPetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "materielAppareilmodifie"
            "materielAppareilnonModifie"
            "materiel6400ISO"
            "materiel12800ISO"
            "materiel400ISO"
          ]
        }
      }
    ) {
      ...FragmentImagePaysageTresPetit
    }
    imagesPaysageMicro: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "materielEosRaBalanceDesBlancs1"
            "materielEosRaBalanceDesBlancs2"
            "materielEosRaBalanceDesBlancs3"
          ]
        }
      }
    ) {
      ...FragmentImagePaysageMicro
    }
  }
`

export default function TestEOSRa() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageTestEOSRa")
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  let images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesPaysagePetit",
    "imagesPaysageTresPetit",
    "imagesPaysageMicro"
  )

  return (
    <LayoutTutorielFR
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Introduction">
        <p>
          Canon m’a prêté pour mon séjour au Chili de décembre 2019 un boîtier
          hybride EOS Ra,{" "}
          <strong>une déclinaison astrophotographique du Canon EOS R</strong>.
          Ce n’est pas la première fois que Canon propose un boîtier modifié
          constructeur pour les astrophotographes : les prédécesseurs de l’EOS
          Ra étaient les EOS 20Da (2005) et l’EOS 60Da (2012). D’autres
          constructeurs se sont également prêtés à l’exercice comme Nikon et son
          très cher D810a.
        </p>
        <p>
          En tout cas, dès la réception, le carton annonce la couleur : le
          boîtier est modifié pour percevoir le rouge des nébuleuses… une jolie
          photo de la nébuleuse Californie (NGC 1499) le décore en effet.
          D’ordinaire, cette nébuleuse est quasi invisible pour les boîtiers non
          modifiés.
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "materielBoiteEosRa")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "materielCanonEOSRa")}
            langue={page.langue}
          />
        </div>
      </Section>
      <Section titre="Un boîtier modifié, pour quoi faire ?">
        <p>
          Votre appareil photo se doit{" "}
          <strong>de reproduire fidèlement les couleurs</strong> que vous
          percevez à l’œil nu. Mais ce n’est pas si simple : votre appareil se
          doit du coup aussi reproduire les défauts de perception de nos yeux,
          qui sont plus sensibles au vert qu’au rouge et au bleu, et qui ne
          perçoivent pas du tout les infra rouges.
        </p>
        <p>
          Pourtant, le capteur de votre appareil est sensible à toutes ces
          longueurs d’ondes lumineuses. Plusieurs dispositifs ont donc été
          inventés pour que les couleurs qu’il restitue soient identiques à
          celles perçues par nos yeux. Il est donc rendu plus sensible au vert
          grâce à la matrice de Bayer (qui permet aussi de réaliser le rendu
          couleur d’une image) et un filtre anti-infrarouge (un « bout de verre
          » de quelques millimètres d’épaisseur) a été placé à sa surface.
        </p>
        <p>
          C’est ce filtre anti-infrarouge qui est problématique pour pratiquer
          l’astrophotographie car il coupe{" "}
          <strong>les longueurs d’ondes rouges</strong> qui sont émises par les
          nébuleuses (la raie <strong>« H alpha »</strong>). Pour obtenir de
          meilleurs résultats en astrophotographie, il est donc nécessaire de le{" "}
          <strong>retirer</strong> ou de le <strong>remplacer</strong>, chose
          que propose d’origine Canon avec son EOS Ra.
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "materielAppareilnonModifie")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "materielAppareilmodifie")}
            langue={page.langue}
          />
        </div>
        <p>
          Images précédentes : simulation de deux images brutes réalisées avec
          un appareil non modifié (à gauche) et un appareil photo modifié (à
          droite). Une tache rouge apparaît avec l’appareil modifié : il s’agit
          de la nébuleuse Californie (NGC 1499).
        </p>
      </Section>
      <Section titre="L’ergonomie">
        <p>
          Pour les habitués des réflex, passer à l’hybride est un{" "}
          <strong>grand changement</strong>. Le plus grand d’entre eux concerne
          la visée électronique. Les constructeurs ont vraiment fait{" "}
          <strong>des progrès</strong> d’années en années, l’image affichée est
          maintenant très bien définie. Mais celle de l’EOS R semble peut être
          toujours un peu lente pour me faire oublier la visée optique.
        </p>
        <p>
          Le plus grand intérêt de l’EOS Ra par rapport à un boîtier comme l’EOS
          5D Mark IV, c’est son <strong>écran orientable</strong>. Grâce à lui,
          il n’est plus nécessaire de se mettre à genoux sur des sols parfois
          très inconfortables. Celui du Ra permet en plus de zoomer jusqu’à 30
          fois sur les étoiles ce qui est appréciable pour effectuer une mise au
          point nocturne précise.
        </p>
        <Image
          image={obtenirImage(images, "materielEcranarriereEOSRa")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Quelques pingreries">
        <p>
          Grande surprise : l’EOS Ra ne possède{" "}
          <strong>pas d’intervallomètre intégré</strong>. Alors que son grand
          frère l’EOS 5D Mark IV et même son petit frère l’EOS Rp en possèdent
          un. Le constat est identique pour l’application smartphone Canon
          Camera Connect qui ne propose pas cette fonctionnalité.{" "}
          <strong>
            Quel dommage pour un boîtier dédié à l’astrophotographie…
          </strong>{" "}
          L’interface de la télécommande n’est non plus pas la même que celle
          des EOS 6D, 5D, etc. Heureusement, un adaptateur existe, mais il vous
          faudra l’acheter.
        </p>
        <p>
          Quant à la bague de conversion de monture RF-&gt;EF qui est fournie
          avec l’EOS R « normal », elle n’est pas incluse non plus, probablement
          pour faire baisser le prix de ce boîtier de niche.
        </p>
      </Section>
      <Section titre="Performances sous le ciel nocturne">
        <p>
          Le capteur de l’EOS Ra est un capteur dérivé de l’EOS 5D Mark IV. Il
          en reprend <strong>donc les qualités</strong>, dont un capteur très
          propre dès les plus basses sensibilités ISO. Le capteur semble
          ISO-invariant à partir de 6400 ISO, mais je n’ai pas eu le temps de
          faire des mesures précises.{" "}
          <strong>L’autofocus est également très performant</strong> dans le
          noir. Si vous possédez un objectif bien ouvert, il suffit de « cliquer
          » sur une étoile pour que le boîtier fasse tout seul la mise au point.
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "materiel400ISO")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "materiel6400ISO")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "materiel12800ISO")}
            langue={page.langue}
          />
        </div>
        <p>
          Le capteur de l’EOS Ra est très propre dès les plus bas ISO : 400 ISO
          (à gauche), 6400 ISO (milieu), 12&nbsp;800 ISO (à droite).
        </p>
      </Section>
      <Section titre="L’EOS Ra vaut-il le « coût » ?">
        <p>
          Pour un usage exclusivement astrophotographique, l’EOS Ra est hélas{" "}
          <strong>cher</strong> : 2750 euros. Un coût qui s’explique
          probablement par le marché de niche qu’est celui de
          l’astrophotographie, la fabrication de ce boîtier obligeant sans
          doutes la création de processus très spécifiques pour finalement peu
          de modèles vendus.
        </p>
        <p>
          <strong>Une alternative</strong> est de faire modifier un boîtier « R
          » normal (2&nbsp;300€ environ) par une société comme la société{" "}
          <Lien urlExterne="https://www.eosforastro.com/">EOS for astro</Lien>{" "}
          pour 430€. On en revient alors au prix du Ra, mais on perd la garantie
          constructeur ce qui n’est pas une bonne option. L’autre option si vous
          souhaitez rester sur un capteur plein format est le Canon RP. Ce
          boîtier coûte environ 1&nbsp;300€, est plus léger, possède le même
          capteur que le 6D Mark II. Par contre, sa batterie est plus petite et
          offre probablement une autonomie moindre. Quant à la modification,
          elle coûte 430€ pour ce modèle aussi.
        </p>
        <Image
          image={obtenirImage(images, "materielUnchaletSousLesEtoiles")}
          langue={page.langue}
        />
        <Image
          image={obtenirImage(images, "materielPharenocturne")}
          langue={page.langue}
        />
        <p>
          Par contre, si vous envisagez <strong>à la fois</strong> une
          utilisation « <strong>diurne</strong> » et « <strong>nocturne</strong>{" "}
          » de votre boîtier alors l’achat d’un EOS Ra{" "}
          <strong>prend tout son sens.</strong>
        </p>
        <p>
          Par rapport à un boîtier modifié artisanalement, il possède en effet
          le grand avantage d’avoir{" "}
          <strong>
            une balance des blancs réglable et effective directement sur le
            boîtier
          </strong>{" "}
          tout en étant sensible à la raie H-Alpha comme le montrent les images
          ci-dessous. En effet, les blancs restent « blancs » quelle que soit la
          balance des blancs que vous réglez sur l’appareil (soleil, ombre,
          tungstène…). Sur un boîtier modifié, il faudrait réaliser ce réglage
          sur votre logiciel de post-production comme Lightroom, sous peine
          d’avoir une dominante rouge prononcée quelle que soit la balance des
          blancs choisie sur votre boîtier (seule la balance des blancs «
          manuelle » réalisée par l’opérateur qui a modifié le boîtier présente
          un réglage correct).
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "materielEosRaBalanceDesBlancs1")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "materielEosRaBalanceDesBlancs2")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "materielEosRaBalanceDesBlancs3")}
            langue={page.langue}
          />
        </div>
      </Section>
      <Section titre="Pour conclure">
        <p>
          Le bilan est tout de même <strong>positif</strong> pour l’EOS Ra. J’ai
          été séduit par sa compacité, son ergonomie et par les performances de
          son capteur. Par contre, il est vraiment dommage{" "}
          <strong>que pour le prix</strong> Canon n’ait pas doté le boîtier d’un
          intervallomètre intégré. Son grand frère l’EOS 5D Mark IV et même son
          petit frère l’EOS Rp en possèdent pourtant un. La bague de conversion
          RF -&gt; EF n’est pas incluse d’origine non plus, il faudra donc
          ajouter le prix de cet accessoire (200€) à votre budget si vous
          comptez utiliser les objectifs Canon que vous possédez déjà.
        </p>
        <p>
          Bref, si vous êtes déjà chez Canon avec un budget confortable, que
          vous recherchez un boîtier compact 24x36 et{" "}
          <strong>
            performant pour à la fois l’astrophotographie et la photographie
            diurne
          </strong>
          , l’EOS Ra est clairement une option à envisager. Pour un budget
          moindre, regardez peut-être du côté d’un EOS Rp que vous feriez
          modifier, ou bien sûr du côté d’un boîtier modifié acheté d’occasion.
        </p>
      </Section>
    </LayoutTutorielFR>
  )
}
